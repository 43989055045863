import { FC, useMemo, useRef, useEffect, useState, Fragment } from 'react';
import Head from 'next/head';
import type { GetStaticProps } from 'next';
import cn from 'classnames';
import Button from '@components/Button';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import pageNotFoundData from '@data/404';
import styles from '../styles/404.module.scss';

const projects = [
  {
    name: 'keepsimple',
    link: '/',
  },
  {
    name: 'UX Core',
    link: '/uxcore',
  },
  {
    name: 'UX Core Guide',
    link: '/uxcg',
  },
  {
    name: 'UX Cat',
    link: '/uxcat',
  },
];

interface Custom404Props {
  intl: any;
  locale: 'en' | 'ru';
}

const Custom404: FC<Custom404Props> = ({ intl, locale }) => {
  const router = useRouter();
  const timeoutRef: any = useRef();
  const [counter, setCounter] = useState(10);
  const { asPath } = router;

  const cameFrom = useMemo(() => {
    let pageIndex = 0;

    const isUXCorePage = asPath.includes('/uxcore');
    const isUXCGPage =
      asPath.includes('/uxcg') &&
      !asPath.includes('/uxcgdiy') &&
      !asPath.includes('/uxcgstory');
    const isUXCatPage = asPath.includes('/uxcat');

    if (isUXCorePage) pageIndex = 1;
    if (isUXCGPage) pageIndex = 2;
    if (isUXCatPage) pageIndex = 3;

    return projects[pageIndex];
  }, [asPath]);

  const handleRedirect = useCallback(() => {
    router.push(cameFrom.link, null).then(r => r);
  }, [cameFrom]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      const nextCounter = counter - 1;
      if (nextCounter === 0) handleRedirect();
      else setCounter(nextCounter);
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [counter]);

  const { title, message1, message2, backButtonLabel } = intl;
  const errorPage = process.env.NEXT_PUBLIC_DOMAIN + '/404';
  const errorPageImage =
    process.env.NEXT_PUBLIC_DOMAIN + '/assets/icons/info.svg';

  return (
    <Fragment>
      <Head>
        <meta name="robots" content={'index, follow'} />
        <title>keep-simple | Error Page</title>
        <meta name="description" content={'404 page - page not found'} />
        <meta name="keywords" content={'404 page - page not found'} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2"
        />
        {/* GOOGLE */}
        <meta itemProp="name" content={'404 page'} />
        <meta itemProp="description" content={'404 page - page not found'} />

        {/* FACEBOOK */}
        <meta property="og:title" content={'404 page'} />
        <meta property="og:description" content={'404 page - page not found'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={errorPage} />
        <meta property="og:image" content={errorPageImage} />
      </Head>
      <div
        className={cn(styles.PageNotFound, {
          [styles.Ru]: locale === 'ru',
        })}
      >
        <img src="/assets/icons/info.svg" alt="Exclamation mark image" />
        <div className={styles.Title}>{title}</div>
        <div className={styles.Description}>
          {message1}
          {cameFrom.name}
          {message2}
          {counter}...
        </div>
        <Button label={backButtonLabel} onClick={handleRedirect} />
      </div>
    </Fragment>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  // TODO: make this more neat
  // @ts-ignore
  const intl = pageNotFoundData[locale];

  return {
    props: {
      intl,
      locale,
    },
  };
};

export default Custom404;
